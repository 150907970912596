<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :subtitle="$store.state.settings.pageData.subtitle || $t('hr.title')"
      :title="$store.state.settings.pageData.title || $t('hr.title')"
      :icon="$store.state.settings.pageData.icon || 'mdi-account-tie'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      :side-navigation="navigationSelect"
      @filter="$store.state.settings.filtersData = $event"
      navigation-route="hr"
  >
<!--    -->

    <template #header_action.desktop>
      <div class="d-flex align-center">

        <ft-select
            v-if="($store.state.settings.pageData.headerAction || !!$store.state.settings.pageData.headerDropdownActions) && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
            :items="$store.state.settings.pageData.headerDropdownActions || []">
          <ws-button
              @click="!$store.state.settings.pageData.headerDropdownActions ? $store.state.settings.pageData.headerAction() : () => {}"
              :label-raw="$store.state.settings.pageData.headerActionText"
              :click-stop="!$store.state.settings.pageData.headerDropdownActions"
              :icon="!!$store.state.settings.pageData.headerDropdownActions ? 'mdi-menu-down' : null"
          />
        </ft-select>

      </div>
    </template>

    <router-view></router-view>

  </dash-page-new>
</template>

<script>
export default {
  name: "HumanResources",
  computed : {
    navigationSelect() {
      return [
        { text : this.$t('StaffList')    , path : '' , value : '' , icon : 'mdi-abacus'},
        { text : this.$t('Structure')    , path : 'structure' , value : '' , icon : 'mdi-sitemap'},
        { text : this.$t('hr.positions.title') , path : 'positions' , value : 'positions'    , icon : 'mdi-briefcase-outline'},
      ]
    }
  }
}
</script>

<style scoped>

</style>